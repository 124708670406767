import { useRef, useState } from 'react'
import Privacy from './Privacy'
import InputMask from 'react-input-mask'
import API from './api'
import './App.css'
import GitHub from './assets/github-mark.svg'
import CWMarketingLogo from './assets/cw-marketing.svg'
import Clockwork from './assets/clockwork.svg'
import Mockup from './assets/mockup.png'
import Pad from './assets/pad.png'

// Partners Image
import Seabass from './assets/partners/seabass.png'
import DenDener from './assets/partners/dendener.png'
import DogOsminog from './assets/partners/dogosminog.png'
import Baker from './assets/partners/baker.png'
import Vita from './assets/partners/vita.png'
import Udon from './assets/partners/udon.png'

// Abilities Image
import Reload from './assets/abilities/reload.png'
import Fingerprint from './assets/abilities/fingerprint.png'
import Clock from './assets/abilities/clock.png'
import Pin from './assets/abilities/pin.png'
import Piechart from './assets/abilities/piechart.png'

const App = () => {
    const requestAccessRef = useRef()
    const privacyRef = useRef()
    const requestButtonRef = useRef()
    const notificationRef = useRef()

    const [phone, setPhone] = useState('')
    const [name, setName] = useState('')

    const onRequestAccessClick = (e) => {
        e.preventDefault()
        if (requestAccessRef && requestAccessRef.current) {
            fadeIn(requestAccessRef.current)

            const content = requestAccessRef.current.firstChild
            content.classList.remove('transform-out')
            content.classList.add('transform-in')
        }
    }

    const onPrivacyClick = (e) => {
        e.preventDefault()
        if (privacyRef && privacyRef.current) {
            fadeIn(privacyRef.current)

            const content = privacyRef.current.firstChild
            content.classList.remove('transform-out')
            content.classList.add('transform-in')
        }
    }

    const fadeIn = (el) => {
        el.style.opacity = 0
        el.style.display = 'block'
        ;(function fade() {
            let val = parseFloat(el.style.opacity)
            if (!((val += 0.05) > 1)) {
                el.style.opacity = val
                requestAnimationFrame(fade)
            }
        })()
    }

    const fadeOut = (el) => {
        el.style.opacity = 1
        ;(function fade() {
            if ((el.style.opacity -= 0.1) < 0) {
                el.style.display = 'none'
            } else {
                requestAnimationFrame(fade)
            }
        })()
    }

    const onPopupClick = (e, target) => {
        if (target && target.current) {
            if (e.target !== target.current) return

            fadeOut(target.current)

            const content = target.current.firstChild
            content.classList.remove('transform-in')
            content.classList.add('transform-out')
        }
    }

    const onSubmit = (e) => {
        e.preventDefault()

        if (notificationRef && notificationRef.current) {
            notificationRef.current.classList.remove('success')
            notificationRef.current.classList.remove('error')
            notificationRef.current.innerHTML = ''
        }

        if (requestButtonRef && requestButtonRef.current) {
            requestButtonRef.current.disabled = true
        }

        const payload = {
            name: 'contacts',
            fields: [
                {
                    key: 'name',
                    value: name,
                },
                {
                    key: 'phone',
                    value: phone,
                },
            ],
        }

        fetch('https://tg.clockwork.one/v1/notify', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-Access-Key': '5wer1e316x6c7wbtffwxc8wpt959420fa217d98wera844ergxt0r9',
            },
            body: JSON.stringify(payload),
        })
            .then((res) => {
                if (res.ok) {
                    if (notificationRef && notificationRef.current) {
                        notificationRef.current.classList.add('success')
                        notificationRef.current.innerHTML = 'Ваше запрос успешно отправлен!'
                    }
                    setTimeout(() => {
                        if (notificationRef && notificationRef.current) {
                            notificationRef.current.classList.remove('success')
                            notificationRef.current.innerHTML = ''
                        }
                    }, 1500)
                } else throw new Error()
            })
            .catch((e) => {
                console.log(e)
                if (notificationRef && notificationRef.current) {
                    notificationRef.current.classList.add('error')
                    notificationRef.current.innerHTML = 'При отправке запроса произошла ошибка'
                }
            })
            .finally(() => {
                if (requestButtonRef && requestButtonRef.current) {
                    requestButtonRef.current.disabled = false
                }
            })
    }

    return (
        <>
            <div
                ref={privacyRef}
                onClick={(e) => onPopupClick(e, privacyRef)}
                className='popup popup--privacy'>
                <div className='popup__content'>
                    <Privacy />
                </div>
            </div>

            <div
                ref={requestAccessRef}
                onClick={(e) => onPopupClick(e, requestAccessRef)}
                className='popup'>
                <div className='popup__content'>
                    <h2 className='content__heading'>Начать работать с CWMarketing</h2>
                    <form className='popup__content__form' onSubmit={onSubmit}>
                        <InputMask
                            mask='+7 (999) 999-99-99'
                            placeholder='Телефон'
                            className='form-input'
                            onChange={({ target: { value } }) => setPhone(value)}
                            value={phone}
                        />
                        <input
                            type='text'
                            placeholder='Имя'
                            className='form-input'
                            onChange={({ target: { value } }) => setName(value)}
                            value={name}
                        />
                        <strong ref={notificationRef} className='popup__notification' />
                        <button type='submit' ref={requestButtonRef} className='request-access'>
                            Попробовать
                        </button>
                        <span className='popup__disclaimer'>
                            Нажимая на кнопку «Попробовать», вы принимаете политику обработки
                            персональных данных.
                        </span>
                    </form>
                </div>
            </div>

            <main className='main'>
                <header className='header'>
                    <object
                        id='logo'
                        data={CWMarketingLogo}
                        aria-label='Логотип'
                        type='image/svg+xml'
                    />
                    <div className='header__links'>
                        <a
                            className='header__enter'
                            href='https://app2.cw.marketing?utm_source=main-site'
                            target='_blank'
                            rel='nofollow noreferrer noopener'>
                            Войти
                        </a>
                        <button className='request-access' onClick={onRequestAccessClick}>
                            Попробовать
                        </button>
                    </div>
                </header>

                <section className='content'>
                    <div className='content__body'>
                        <div className='content__scopes'>
                            <span>Доставка.</span>
                            <span>Маркетинг.</span>
                            <span>Лояльность.</span>
                        </div>

                        <p className='content__description'>
                            Быстро и просто запустим
                            <br />
                            сайт и мобильное приложение
                        </p>

                        <button
                            className='request-access request-access--content'
                            onClick={onRequestAccessClick}>
                            Попробовать
                        </button>

                        <img src={Mockup} alt='Backer' className='content__mockup' />
                    </div>
                    <div className='content__footer'>
                        <strong>Уже работают с нами</strong>
                        <div
                            className='content__partners'
                            style={{ '--items': partners.length.toString() }}>
                            {partners.map(({ image, alt, link }, i) => (
                                <a
                                    key={`partner_${i}`}
                                    href={link}
                                    className='content__partner'
                                    target='_blank'
                                    rel='noopener noreferrer nofollow'>
                                    <img src={image} alt={alt} title={alt} />
                                </a>
                            ))}
                        </div>
                    </div>
                </section>

                <section className='content__intro'>
                    <h1 className='content__heading'>
                        Увеличиваем
                        <br />
                        прибыль ресторана
                    </h1>
                    <p className='content__intro__description'>
                        Приложение и сайт для заказа доставки, навынос и в зале, программа
                        лояльности, интеграция POS
                    </p>

                    <img src={Pad} alt='' className='content__intro__pad' />
                </section>
            </main>

            <section className='content__abilities'>
                <div className='content__abilities__inner'>
                    {abilities.map(({ title, icon, content: __html }, i) => (
                        <figure className='content__ability' key={`ability_${i}`}>
                            <div className='content__ability__header'>
                                <div className='content__ability__image'>
                                    <img src={icon} alt={title} />
                                </div>
                            </div>
                            <div className='content__ability__description'>
                                <figcaption className='content__ability__title'>{title}</figcaption>
                                <p
                                    className='content__ability__content'
                                    dangerouslySetInnerHTML={{ __html }}
                                />
                            </div>
                        </figure>
                    ))}

                    <figure className='content__ability--special'>
                        <div className='content__ability__header--logo'>
                            <img src={CWMarketingLogo} className='cw-logo' alt='CWMarketing' />
                        </div>
                        <div className='content__ability__description'>
                            <figcaption className='content__ability__title'>
                                И много другое
                            </figcaption>
                            <button className='request-access' onClick={onRequestAccessClick}>
                                Попробовать
                            </button>
                        </div>
                    </figure>
                </div>
            </section>

            <main className='main'>
                <section className='content__pricing'>
                    <h1 className='content__heading'>Тарифы</h1>
                    <div className='content__pricing__list'>
                        <div className='content__price content__price--start'>
                            <h2>Начало</h2>
                            <strong>Тариф подойдет для небольшой компании</strong>
                            <button className='request-access' onClick={onRequestAccessClick}>
                                Попробовать
                            </button>
                            <ul>
                                <li>
                                    <strong>сайт</strong> для доставки;
                                </li>
                                <li>
                                    <strong>CRM-система</strong> для приема заказов и управления
                                    гостями заведения;
                                </li>
                                <li>
                                    <strong>стилизация шаблона</strong> в соответствие с брендбуком
                                    компании;
                                </li>
                                <li>онлайн-оплата;</li>
                                <li>
                                    подключение к <strong>POS-терминалам</strong>;
                                </li>
                                <li>
                                    выпуск карт <strong>Apple Wallet</strong> и{' '}
                                    <strong>Google Wallet</strong> для участников бонусной
                                    программы.
                                </li>
                            </ul>

                            <div className='content__price__footer'>
                                <strong className='content__price__price'>59 990₽</strong>
                                <span className='content__price__disclaimer'>
                                    далее <strong>4 990₽</strong> в месяц за 1 ресторан
                                </span>
                            </div>
                        </div>

                        <div className='content__price content__price--growth'>
                            <h2>Развитие</h2>
                            <strong>Тариф подойдет для активно развивающейся организации</strong>
                            <button className='request-access' onClick={onRequestAccessClick}>
                                Попробовать
                            </button>
                            <ul>
                                <li>
                                    все тоже самое, что в тарифе <strong>"Начало"</strong>;
                                </li>
                                <li>
                                    мобильные приложения <strong>iOS</strong> и{' '}
                                    <strong>Android</strong>;
                                </li>
                                <li>
                                    <strong>неограниченное</strong> количество ресторанов;
                                </li>
                                <li>
                                    рассылка <strong>Push-уведолений</strong>.
                                </li>
                            </ul>
                            <div className='content__price__footer'>
                                <strong className='content__price__price'>99 990₽</strong>
                                <span className='content__price__disclaimer'>
                                    далее <strong>4 990₽</strong> в месяц за 1 ресторан
                                </span>
                            </div>
                        </div>

                        <div className='content__price content__price--enterprise'>
                            <h2>Бизнес</h2>
                            <strong>
                                Для тех кому нужен уникальный дизайн и специфичный функционал
                            </strong>
                            <button className='request-access' onClick={onRequestAccessClick}>
                                Попробовать
                            </button>
                            <ul>
                                <li>
                                    все тоже самое, что в тарифе <strong>"Развитие"</strong>;
                                </li>
                                <li>
                                    разработка <strong>собственного дизайна</strong>;
                                </li>
                                <li>
                                    <strong>интеграция сторонних сервисов</strong> компании;
                                </li>
                                <li>
                                    <strong>разработка</strong> новых модулей.
                                </li>
                            </ul>
                            <div className='content__price__footer'>
                                <strong className='content__price__price'>индивидуально</strong>
                                <span className='content__price__disclaimer'>
                                    далее <strong>4 990₽</strong> в месяц за 1 ресторан
                                </span>
                            </div>
                        </div>
                    </div>
                </section>

                <footer className='footer'>
                    <div className='footer__left'>
                        <a
                            href='https://github.com/clockworkone'
                            className='link'
                            rel='nofollow noopener noreferrer'>
                            <img src={GitHub} alt='GitHub' title='GitHub' />
                        </a>
                        <div className='address'>
                            <span>660125, Россия, г. Красноярск</span>
                            <span>ул. Партизана Железняка, 19д, офис 201</span>
                        </div>

                        <div>
                            <button className='btn--privacy' onClick={onPrivacyClick}>
                                Политика обработки персональных данных
                            </button>
                        </div>
                    </div>

                    <div className='footer__right'>
                        <a
                            href='https://clockwork.one/?utm_source=cw.marketing'
                            target='_blank'
                            rel='noopener noreferrer'>
                            <img className='footer__clockwork' src={Clockwork} alt='Clockwork' />
                        </a>
                    </div>
                </footer>
            </main>
        </>
    )
}

const abilities = [
    {
        title: 'Интеграция',
        icon: Reload,
        content:
            'Мы умеем интегрироваться с системами: <strong>IIKO</strong>, <strong>r_keeper</strong> и <strong>Tillypad</strong>',
    },
    {
        title: 'Брендинг',
        icon: Fingerprint,
        content:
            'Учтем дизайн вашего заведения в дизайне <strong>сайта</strong> и <strong>мобильного приложения</strong>',
    },
    {
        title: 'Умные категории',
        icon: Clock,
        content:
            'Научим ваше меню работать по времени, напрмиер: <strong>бизнес-ланч</strong> или <strong>завтрак</strong>',
    },
    {
        title: 'Зоны доставки',
        icon: Pin,
        content:
            'Укажите район действия вашей <strong>доставки</strong> и ее стоимость, а мы все посчитаем',
    },
    {
        title: 'Сегментация',
        icon: Piechart,
        content: 'Предлагайте пользователю то, что он действительно <strong>хочет</strong>',
    },
]

const partners = [
    {
        alt: 'Seabass Group',
        // link: 'https://seabassauvignon.ru',
        image: Seabass,
    },
    {
        alt: 'Vita Margarita',
        link: 'https://vmdelivery.ru',
        image: Vita,
    },
    {
        alt: 'Baker Street',
        link: 'https://baker.delivery',
        image: Baker,
    },
    {
        alt: 'DogOsminog',
        link: 'https://dog-osminog.ru',
        image: DogOsminog,
    },
    {
        alt: 'DenDener',
        // link: 'https://baker.delivery',
        image: DenDener,
    },
    {
        alt: 'Udon',
        image: Udon,
    },
]

export default App
